$( document ).ready(function() {

    //Carousel
    $('.reviews__carousel').owlCarousel({
        loop: false,
        autoplay: false,
        responsiveClass:true,
        items:1,
        nav: true,
        navText: [],
        mouseDrag: false
    });

    $('.videos__carousel').owlCarousel({
        loop: false,
        autoplay: false,
        video: true,
        items: 1,
        nav: true,
        navText: []

    })
    $('.skirts__carousel').owlCarousel({
        loop: true,
        autoplay: true,
        items: 1,
        margin: 30,
        nav: false,
        responsive : {
            0 : {
                items: 1
            },
            480 : {
                items: 2
            },
            768 : {
                items: 3
            },
            992 : {
                items: 4
            },
            1200 : {
                items: 5
            },
            1600 : {
                items: 6
            }
        }
    })

    //Validation
    function hasHtml5Validation () {
        return typeof document.createElement('input').checkValidity === 'function';
    }

    if (hasHtml5Validation()) {
        $('form').submit(function (e) {
            if (!this.checkValidity()) {
                e.preventDefault();
                $(this).addClass('invalid');
            } else {
                $(this).removeClass('invalid');
            }
        });
    }

    //E-mail Ajax Send
    //Documentation & Example: https://github.com/agragregra/uniMail
    $("form").submit(function() { //Change
        var th = $(this);
        $.ajax({
            type: "POST",
            url: "mail.php", //Change
            data: th.serialize()
        }).done(function() {
            var inst = $('[data-remodal-id=modal-thanks]').remodal();
            inst.open();
            
            setTimeout(function() {
                // Done Functions
                th.trigger("reset");
            }, 1000);
        });
        return false;
    });

    //Thumb change
    $('.reviews__thumb').click(function() {
        var newImg = $(this).find('img').attr('src');

        $(this).parent().parent().find('.reviews__picture img').attr('src', newImg);
    });

    //Which Form
    $("a[data-remodal-target='modal']").click(function(e) {
        e.preventDefault();
        var whatForm = $(this).attr('data-form');
        
        $('.remodal[data-remodal-id="modal"] input[name="whatform"]').val(whatForm);
    });

    $("a[data-remodal-target='modal-img']").click(function(e) {
        e.preventDefault();
        var newImg = $(this).children().attr('src');
        
        $('.remodal[data-remodal-id="modal-img"] img').attr('src', newImg);
    });

    //Scrolling to element
    $('.main-menu a').click( function(){ // ловим клик по ссылке с классом go_to
    var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .
        if ($(scroll_el).length != 0) { // проверим существование элемента чтобы избежать ошибки
        $('html, body').animate({ scrollTop: $(scroll_el).offset().top }, 500); // анимируем скроолинг к элементу scroll_el
        }
        return false; // выключаем стандартное действие
    });

    moveImg();

});

function moveImg() {
    $('.reviews__thumb img').each(function() {
        var imgWidth = $(this).width();
        var imgHeight = $(this).height();

        if (imgHeight > imgWidth) {
            $(this).css('top', '-7rem');
        }
    });
}

//Responsice videos
// Find all YouTube videos
var $allVideos = $(".more-info__videos iframe"),

    // The element that is fluid width
    $fluidEl = $(".more-info__videos");

// Figure out and save aspect ratio for each video
$allVideos.each(function() {

  $(this)
    .data('aspectRatio', this.height / this.width)

    // and remove the hard coded width/height
    .removeAttr('height')
    .removeAttr('width');

});

// When the window is resized
$(window).resize(function() {

  var newWidth = $fluidEl.width();

  // Resize all videos according to their own aspect ratio
  $allVideos.each(function() {

    var $el = $(this);
    $el
      .width(newWidth)
      .height(newWidth * $el.data('aspectRatio'));

  });

// Kick off one resize to fix all videos on page load
}).resize();